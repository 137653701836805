import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { camelCase, snakeCase } from 'lodash-es';
import mapKeysDeep from 'map-keys-deep-lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// #camelCase
@Injectable()
export class KeyToCamelCaseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newReqBodySnakeCase: unknown = mapKeysDeep(request.body, (v, k) => snakeCase(k));
    const newRequest: HttpRequest<unknown> = request.clone({ body: newReqBodySnakeCase });

    return next.handle(newRequest).pipe(
      map((event) => {
        if (
          event instanceof HttpResponse &&
          typeof event.body === 'object' &&
          !(event as HttpResponse<unknown>).url.includes('i18n')
        ) {
          const camelCaseObject = mapKeysDeep(event.body, (v, k) => camelCase(k));
          const modEvent = event.clone({ body: camelCaseObject });

          return modEvent;
        }
        if (event) {
          return event;
        }
        return event;
      }),
    );
  }
}
