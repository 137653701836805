import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, TextOnlySnackBar } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private massage = {
    close: 'Закрыть',
    ok: 'Успешно',
    error: 'Ошибка',
  };

  constructor(public snackBar: MatSnackBar) {}

  public showError(
    value: string | HttpErrorResponse,
    duration: number = 4000, // MAT_SNACK_BAR_DEFAULT_OPTIONS
  ): MatSnackBarRef<TextOnlySnackBar> {
    switch (true) {
      case value instanceof HttpErrorResponse:
        return this.openSnackBar(
          (value as HttpErrorResponse).error.message,
          this.massage.close,
          duration,
        );
      case typeof value === 'string':
        return this.openSnackBar(value as string, this.massage.close, duration);
      default:
        console.error('DEV_ERROR: Unknown error with error in snackbar.');
        return this.openSnackBar('Неизвестная ошибка', this.massage.close, duration);
    }
  }

  public showOk(okMassage, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
    return this.openSnackBar(okMassage, this.massage.close, duration);
  }

  private openSnackBar(
    message: string,
    action: string,
    duration: number,
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, {
      duration,
    });
  }
}
