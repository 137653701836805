import {
  MyUnitSDKService,
  SaveMyUnitParamsRequestParams,
} from '@clanhall-sdk/api/myUnit.sdk.service';
import { CharProfileSDKModel } from '@clanhall-sdk/model/charProfile.sdk.model';
import { CharSearchResponseSDKModel } from '@clanhall-sdk/model/charSearchResponse.sdk.model';
import { CommProfileSDKModel } from '@clanhall-sdk/model/commProfile.sdk.model';
import { CommSearchResponseSDKModel } from '@clanhall-sdk/model/commSearchResponse.sdk.model';
import { GuildProfileSDKModel } from '@clanhall-sdk/model/guildProfile.sdk.model';
import { GuildSearchResponseSDKModel } from '@clanhall-sdk/model/guildSearchResponse.sdk.model';
import { Response200SDKModel } from '@clanhall-sdk/model/response200.sdk.model';
import { Response201SDKModel } from '@clanhall-sdk/model/response201.sdk.model';
import { SaveAvatarResponseSDKModel } from '@clanhall-sdk/model/saveAvatarResponse.sdk.model';
import { UnitParameterSDKModel } from '@clanhall-sdk/model/unitParameter.sdk.model';
import { UserProfileSDKModel } from '@clanhall-sdk/model/userProfile.sdk.model';
import { StateContext } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import {
  BaseUnitStateModel,
  BaseUnitStateModelWithLists,
} from '@store/common-states/units/shared/base/base-units.state.directive';
import {
  DeleteUnitBase,
  QueryUnitByIdBase,
  SaveUnitAvatarBase,
  SaveUnitParamsBase,
} from '@store/common-states/units/shared/base/shared-units.actions';
import { Header } from '@store/ux-states/header/header.actions';
import { defer, Observable } from 'rxjs';
import { finalize, switchMapTo, tap } from 'rxjs/operators';

export abstract class BaseUnitsState {
  constructor(protected myUnitSDKService: MyUnitSDKService) {}

  static filterOnlyMyIds(units: BaseUnitStateModel['loadedUnits']): number[] {
    return Object.keys(units).reduce((acc: number[], unitKey: string) => {
      const unitId = Number(unitKey);
      if (units[unitId].isMy) {
        acc.push(unitId);
      }
      return acc;
    }, []);
  }

  loadingStartBase(context: StateContext<BaseUnitStateModel>): Observable<void> {
    context.patchState({ loading: true });
    return context.dispatch(new Header.TurnOnLoading());
  }

  loadingEndBase(context: StateContext<BaseUnitStateModel>): Observable<void> {
    context.patchState({ loading: false });
    return context.dispatch(new Header.TurnOffLoading());
  }

  changeTitleBase(context: StateContext<BaseUnitStateModel>, title: string): Observable<void> {
    return context.dispatch(new Header.ChangeTitle(title));
  }

  saveUnitParamsBase(
    context: StateContext<BaseUnitStateModel>,
    { unitId, params }: SaveUnitParamsBase,
    unitType: SaveMyUnitParamsRequestParams['unitType'],
    currentTitle: string,
    loadingTitle: string,
  ): Observable<void> {
    return defer(() =>
      undefined$().pipe(
        switchMapTo(this.changeTitleBase(context, loadingTitle)),
        switchMapTo(this.loadingStartBase(context)),
        switchMapTo(
          this.myUnitSDKService.saveMyUnitParams({
            unitId,
            saveUnitParamsSDKModel: params,
            unitType,
          }),
        ),
        tap((response: UnitParameterSDKModel[]) => {
          const state: BaseUnitStateModel = context.getState();
          context.patchState({
            loadedUnits: {
              ...state.loadedUnits,
              [unitId]: {
                ...state.loadedUnits[unitId],
                params: response,
              },
            },
          });
        }),
        finalize(() => {
          this.changeTitleBase(context, currentTitle);
          this.loadingEndBase(context);
        }),
        switchMapTo(undefined$()),
      ),
    );
  }

  saveUnitAvatarBase(
    context: StateContext<BaseUnitStateModel>,
    { unitId, avatarBlob }: SaveUnitAvatarBase,
    unitType: SaveMyUnitParamsRequestParams['unitType'],
    currentTitle: string,
    loadingTitle: string,
  ): Observable<void> {
    return defer(() => {
      this.changeTitleBase(context, loadingTitle);
      this.loadingStartBase(context);
      return this.myUnitSDKService.saveMyUnitAvatar({ unitId, avatar: avatarBlob, unitType }).pipe(
        tap((response: SaveAvatarResponseSDKModel) => {
          this.changeTitleBase(context, currentTitle);
          const state: BaseUnitStateModel = context.getState();
          context.patchState({
            // @ts-ignore TODO: DEV-201
            loadedUnits: {
              ...state.loadedUnits,
              [unitId]: {
                ...state.loadedUnits[unitId],
                params: state.loadedUnits[unitId].params.map((value: UnitParameterSDKModel) =>
                  value.key === 'avatar' ? { ...value, data: response.avatar } : value,
                ),
              },
            },
          });
        }),
        finalize(() => {
          this.changeTitleBase(context, currentTitle);
          this.loadingEndBase(context);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  deleteUnitBase(
    context: StateContext<BaseUnitStateModelWithLists>,
    { unitId }: DeleteUnitBase,
    currentTitle: string,
    loadingTitle: string,
    query: () => Observable<Response200SDKModel>,
  ): Observable<void> {
    return defer(() => {
      this.changeTitleBase(context as any, loadingTitle);
      this.loadingStartBase(context as any);
      return query().pipe(
        tap(() => {
          const state: BaseUnitStateModelWithLists = context.getState();
          context.patchState({
            ...state,
            ...{
              myUnitsList: state.unitsList.filter((unit) => unit.id !== unitId),
            },
          });
        }),
        finalize(() => {
          this.changeTitleBase(context as any, currentTitle);
          this.loadingEndBase(context as any);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  restoreUnitBase(
    context: StateContext<BaseUnitStateModelWithLists>,
    currentTitle: string,
    loadingTitle: string,
    query: () => Observable<Response200SDKModel>,
  ): Observable<void> {
    return defer(() => {
      this.changeTitleBase(context as any, loadingTitle);
      this.loadingStartBase(context as any);
      return query().pipe(
        finalize(() => {
          this.changeTitleBase(context as any, currentTitle);
          this.loadingEndBase(context as any);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  addMyUnitBase(
    context: StateContext<BaseUnitStateModelWithLists>,
    currentTitle: string,
    loadingTitle: string,
    query: () => Observable<Response201SDKModel>,
  ): Observable<void> {
    return defer(() => {
      this.changeTitleBase(context as any, loadingTitle);
      this.loadingStartBase(context as any);
      return query().pipe(
        finalize(() => {
          this.changeTitleBase(context as any, currentTitle);
          this.loadingEndBase(context as any);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  queryMyUnitsListBase(
    context: StateContext<BaseUnitStateModelWithLists>,
    currentTitle: string,
    loadingTitle: string,
    query: () => Observable<
      CharSearchResponseSDKModel | CommSearchResponseSDKModel | GuildSearchResponseSDKModel
    >,
  ): Observable<void> {
    return defer(() => {
      this.changeTitleBase(context as any, loadingTitle);
      this.loadingStartBase(context as any);
      return query().pipe(
        tap(
          (
            response:
              | CharSearchResponseSDKModel
              | CommSearchResponseSDKModel
              | GuildSearchResponseSDKModel,
          ) => {
            context.patchState({
              unitsList: response.units.data,
              unitsListParams: response.tableParams,
            });
          },
        ),
        finalize(() => {
          this.changeTitleBase(context as any, currentTitle);
          this.loadingEndBase(context as any);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  queryMyArchivedUnitsListBase(
    context: StateContext<BaseUnitStateModelWithLists>,
    currentTitle: string,
    loadingTitle: string,
    query: () => Observable<
      CharSearchResponseSDKModel | CommSearchResponseSDKModel | GuildSearchResponseSDKModel
    >,
  ): Observable<void> {
    return defer(() => {
      this.changeTitleBase(context as any, loadingTitle);
      this.loadingStartBase(context as any);
      return query().pipe(
        tap(
          (
            response:
              | CharSearchResponseSDKModel
              | CommSearchResponseSDKModel
              | GuildSearchResponseSDKModel,
          ) => {
            context.patchState({
              archivedUnitsList: response.units.data,
              archivedUnitsListParams: response.tableParams,
            });
          },
        ),
        finalize(() => {
          this.changeTitleBase(context as any, currentTitle);
          this.loadingEndBase(context as any);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  queryUnitByIdBase(
    context: StateContext<BaseUnitStateModel>,
    unitId: QueryUnitByIdBase['unitId'],
    currentTitle: string,
    loadingTitle: string,
    query: () => Observable<
      CharProfileSDKModel | CommProfileSDKModel | GuildProfileSDKModel | UserProfileSDKModel
    >,
  ): Observable<void> {
    return defer(() => {
      this.changeTitleBase(context, loadingTitle);
      this.loadingStartBase(context);
      return query().pipe(
        tap(
          (
            response:
              | CharProfileSDKModel
              | CommProfileSDKModel
              | GuildProfileSDKModel
              | UserProfileSDKModel,
          ) => {
            const state = context.getState();

            context.patchState({
              loadedUnits: {
                ...state.loadedUnits,
                [unitId]: {
                  isMy: response.isMy,
                  params: response.params,
                  actions: response.actions,
                },
              },
            });
          },
        ),
        finalize(() => {
          this.changeTitleBase(context, currentTitle);
          this.loadingEndBase(context);
        }),
        switchMapTo(undefined$()),
      );
    });
  }
}
