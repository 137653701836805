import { Injectable } from '@angular/core';
import { DocumentsSDKService } from '@clanhall-sdk/api/documents.sdk.service';
import { DocumentInListSDKModel } from '@clanhall-sdk/model/documentInList.sdk.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import { Docs } from '@store/common-states/docs/docs.actions';
import { Header } from '@store/ux-states/header/header.actions';
import { finalize, switchMapTo, tap } from 'rxjs/operators';

export interface IDocsList {
  [key: string]: DocumentInListSDKModel;
}

export interface DocsStateModel {
  docsList: IDocsList;
}

@State<DocsStateModel>({
  name: 'docs',
  defaults: {
    docsList: {},
  },
})
@Injectable()
export class DocsState {
  constructor(private documentsSDKService: DocumentsSDKService) {}

  @Selector()
  static docsList(state: DocsStateModel) {
    return state.docsList;
  }

  @Action(Docs.UpdateDocsList)
  updateDocs(context: StateContext<DocsStateModel>) {
    return undefined$().pipe(
      switchMapTo(context.dispatch(new Header.TurnOnLoading())),
      finalize(() => context.dispatch(new Header.TurnOffLoading())),
      switchMapTo(this.documentsSDKService.returnAllDocuments()),
      tap((docs) => {
        const newDocs: { [key: string]: DocumentInListSDKModel } = docs.reduce(
          (prevValue, currentValue) => ({
            ...prevValue,
            [currentValue.key]: currentValue,
          }),
          {},
        ) as { [key: string]: DocumentInListSDKModel };

        context.patchState({ docsList: { ...context.getState().docsList, ...newDocs } });
      }),
    );
  }

  @Action(Docs.GetDocByKey)
  getDocByKey(context: StateContext<DocsStateModel>, { docKey }: Docs.GetDocByKey) {
    return undefined$().pipe(
      switchMapTo(context.dispatch(new Header.TurnOnLoading())),
      finalize(() => context.dispatch(new Header.TurnOffLoading())),
      switchMapTo(this.documentsSDKService.returnDocument({ key: docKey })),
      tap((response) => {
        context.patchState({
          docsList: {
            ...context.getState().docsList,
            [docKey]: {
              ...context.getState().docsList[docKey],
              ...response,
            },
          },
        });
      }),
    );
  }
}
