import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import { Observable } from 'rxjs';
import { Header } from './header.actions';

export interface HeaderStateModel {
  title: string;
  loading: boolean;
  rightText: string | SafeHtml;
  leftText: string | SafeHtml;
}

const defaults: HeaderStateModel = {
  title: 'ClanHall',
  loading: false,
  rightText: '',
  leftText: '',
};

@State<HeaderStateModel>({
  name: 'header',
  defaults,
})
@Injectable()
export class HeaderState {
  @Selector()
  static loading(state: HeaderStateModel): HeaderStateModel['loading'] {
    return state.loading;
  }

  @Selector()
  static title(state: HeaderStateModel): HeaderStateModel['title'] {
    return state.title;
  }

  @Selector()
  static leftText(state: HeaderStateModel): HeaderStateModel['leftText'] {
    return state.leftText;
  }

  @Selector()
  static rightText(state: HeaderStateModel): HeaderStateModel['rightText'] {
    return state.rightText;
  }

  @Action(Header.ChangeTitle)
  changeTitle(
    context: StateContext<HeaderStateModel>,
    { payload }: Header.ChangeTitle,
  ): Observable<void> {
    const checkedTitle: string = payload || defaults.title;

    if (context.getState().title !== checkedTitle) {
      context.patchState({ title: checkedTitle });
    }
    return undefined$();
  }

  @Action(Header.ChangeLeftText)
  changeLeftText(
    context: StateContext<HeaderStateModel>,
    { leftText }: Header.ChangeLeftText,
  ): Observable<void> {
    const checkedLeftText: string | SafeHtml = leftText || defaults.leftText;

    if (context.getState().leftText !== checkedLeftText) {
      context.patchState({ leftText: checkedLeftText });
    }
    return undefined$();
  }

  @Action(Header.ChangeRightText)
  changeRightText(
    context: StateContext<HeaderStateModel>,
    { rightText }: Header.ChangeRightText,
  ): Observable<void> {
    const checkedRightText: string | SafeHtml = rightText || defaults.rightText;

    if (context.getState().rightText !== checkedRightText) {
      context.patchState({ rightText: checkedRightText });
    }
    return undefined$();
  }

  @Action(Header.TurnOnLoading)
  loadingStart(context: StateContext<HeaderStateModel>): Observable<void> {
    if (!context.getState().loading) {
      context.patchState({ loading: true });
    }
    return undefined$();
  }

  @Action(Header.TurnOffLoading)
  loadingEnd(context: StateContext<HeaderStateModel>): Observable<void> {
    if (context.getState().loading) {
      context.patchState({ loading: false });
    }
    return undefined$();
  }
}
