import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { CoreModule } from '@modules/core/core.module';
import { TranslocoRootModule } from '@modules/transloco/transloco-root.module';
import { MAIN_MODULE_COMPONENTS, MAIN_MODULE_IMPORTS } from './app-module.dependencies';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  imports: [
    ...MAIN_MODULE_IMPORTS,
    CommonModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    TranslocoRootModule,
  ],
  declarations: [...MAIN_MODULE_COMPONENTS],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 500,
      } as MatTooltipDefaultOptions,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
