import { Selector } from '@ngxs/store';

import { AuthStateModel } from './auth.model';
import { AUTH_STATE_TOKEN } from './auth.toke';

export class AuthSelectors {
  @Selector([AUTH_STATE_TOKEN])
  static isUserLoggedIn(state: AuthStateModel) {
    return state.loggedIn;
  }

  @Selector([AUTH_STATE_TOKEN])
  static userId(state: AuthStateModel) {
    return state.userID;
  }

  @Selector([AUTH_STATE_TOKEN])
  static userName(state: AuthStateModel) {
    return state.userName;
  }

  @Selector([AUTH_STATE_TOKEN])
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }
}
