import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthRoutesEnum } from '@modules/auth/auth-routing.constants';
import { WINDOW } from 'features/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Logout if 401 response returned from api
   *
   * @param req The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   *
   * @returns An observable of the event stream.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !request.url.includes('logout') && !request.url.match(/\/me$/)) {
          window.location.href = AuthRoutesEnum.getFullLink(AuthRoutesEnum.logout);
        }
        return throwError(err);
      }),
    );
  }
}
