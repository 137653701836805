import { NgxsConfig } from '@ngxs/store';

import { DocsState } from '@store/common-states/docs/docs.state';
import { HeaderState } from '@store/ux-states/header/header.state';
import { SidebarsState } from '@store/ux-states/sidebars/sidebars.state';
import { WindowState } from '@store/ux-states/window/window.state';
import { environment } from '@environments/environment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SearchCharsUnitsState } from '@store/common-states/search/chars/search-chars-units.state';
import { CharsUnitsState } from '@store/common-states/units/chars/chars-units-state.service';
import { CommsUnitsState } from '@store/common-states/units/comms/comms-units-state.service';
import { GuildsUnitsState } from '@store/common-states/units/guilds/guilds-units-state.service';
import { UserUnitState } from '@store/common-states/units/user/user-unit-state.service';
import { AppConfigState } from '@store/system-states/app-config/app-config.state';
import { SearchCommsUnitsState } from './common-states/search/comms/search-comms-units.state';
import { SearchGuildsUnitsState } from './common-states/search/guilds/search-guilds-units.state';
import { SearchUsersUnitsState } from './common-states/search/users/search-users-units.state';
import { AuthState } from './system-states/auth/auth.state';

const COMMON_STATES: any[] = [
  DocsState,
  GuildsUnitsState,
  CharsUnitsState,
  CommsUnitsState,
  UserUnitState,
];

const SEARCH_STATES: any[] = [
  SearchUsersUnitsState,
  SearchGuildsUnitsState,
  SearchCommsUnitsState,
  SearchCharsUnitsState,
];

const SYSTEM_STATES = [AuthState, AppConfigState];

const UX_STATES = [HeaderState, SidebarsState, WindowState];

export const STATES_MODULES: any[] = [
  ...UX_STATES,
  ...SYSTEM_STATES,
  ...COMMON_STATES,
  ...SEARCH_STATES,
];

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  developmentMode: !environment.production,
};
