<ng-container *transloco="let t">
  <mat-nav-list>
    <!-- <mat-list-item
    *ngIf="isLoggedIn$ | async"
    [routerLink]="settingsRoutesEnum.getFullLink(settingsRoutesEnum.app)"
    (click)="closeSidebar()"
  >
    <mat-icon matListItemIcon>settings</mat-icon>
    <a
      *ngIf="(rightSidebar$ | async).isFull"
      matListItemTitle
      [innerHTML]="t('NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.SETTINGS')"
    ></a>
  </mat-list-item> -->
    <mat-list-item
      *ngIf="isLoggedIn$ | async"
      [routerLink]="authRoutesEnum.getFullLink(authRoutesEnum.logout)"
      (click)="closeSidebar()"
    >
      <mat-icon matListItemIcon>logout</mat-icon>
      <a
        *ngIf="(rightSidebar$ | async).isFull"
        matListItemTitle
        [innerHTML]="t('NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.LOGOUT')"
      ></a>
    </mat-list-item>
    <mat-list-item
      *ngIf="(isLoggedIn$ | async) === false"
      [routerLink]="authRoutesEnum.getFullLink(authRoutesEnum.login)"
      (click)="closeSidebar()"
    >
      <mat-icon matListItemIcon>login</mat-icon>
      <a
        *ngIf="(rightSidebar$ | async).isFull"
        matListItemTitle
        [innerHTML]="t('NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.LOGIN')"
      ></a>
    </mat-list-item>

    <mat-divider></mat-divider>
  </mat-nav-list>
  <div style="flex: 1"></div>
  <mat-nav-list>
    <mat-divider></mat-divider>
    <a
      href="https://discord.gg/ec3XW2eSQz"
      mat-list-item
      target="_blank"
      [matTooltip]="'Перейти в наш Discord'"
    >
      <mat-icon matListItemIcon svgIcon="logos:discord"></mat-icon>
      <div mat-line [innerHTML]="'Наш Discord'"></div>
    </a>
    <a
      href="https://vk.com/clan_hall"
      mat-list-item
      target="_blank"
      [matTooltip]="'Перейти в группу VK'"
    >
      <mat-icon matListItemIcon svgIcon="logos:vk" color="white"></mat-icon>
      <div mat-line [innerHTML]="'Группа в VK'"></div>
    </a>
    <mat-divider></mat-divider>
    <a
      href="https://discord.gg/5g9ByQUV7x"
      mat-list-item
      target="_blank"
      [matTooltip]="'Написать в наш Discord-канал'"
    >
      <mat-icon matListItemIcon>bug_report</mat-icon>
      <div mat-line [innerHTML]="t('NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.SEND_BUGS')"></div>
    </a>
  </mat-nav-list>
  <mat-nav-list>
    <mat-divider></mat-divider>
    <mat-list-item routerLink="/docs" (click)="closeSidebar()">
      <mat-icon matListItemIcon>info</mat-icon>
      <a
        *ngIf="(rightSidebar$ | async).isFull"
        matListItemTitle
        [innerHTML]="t('NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.INFO')"
      ></a>
    </mat-list-item>
  </mat-nav-list>
</ng-container>
