/**
 * ClanHall API
 * ClanHall API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: admin@clanhall.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CharListResponseSDKModel } from '../model/models';
import { CharProfileSDKModel } from '../model/models';
import { CharSearchResponseSDKModel } from '../model/models';
import { Response401SDKModel } from '../model/models';
import { Response404SDKModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ReturnCharListRequestParams {
    page?: number;
    perPage?: number;
}

export interface ReturnCharProfileRequestParams {
    id: number;
}

export interface ReturnUserCharListRequestParams {
    id: number;
}

export interface SearchCharsRequestParams {
    name?: string;
    code?: number;
    surname?: string;
    game?: number;
    server?: number;
    fraction?: number;
    charRace?: number;
    charClass?: number;
    charGender?: number;
    levelMin?: number;
    levelMax?: number;
    gearScoreMin?: number;
    gearScoreMax?: number;
    charRole?: number;
    user?: string;
    isVerified?: boolean;
    page?: number;
    perPage?: number;
}


@Injectable({
  providedIn: 'root'
})
export class CharSDKService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Ready
     * Returns list of Chars &amp; Parameters for Search &amp; table
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnCharList(requestParameters: ReturnCharListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CharListResponseSDKModel>;
    public returnCharList(requestParameters: ReturnCharListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CharListResponseSDKModel>>;
    public returnCharList(requestParameters: ReturnCharListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CharListResponseSDKModel>>;
    public returnCharList(requestParameters: ReturnCharListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        const perPage = requestParameters.perPage;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CharListResponseSDKModel>(`${this.configuration.basePath}/api/chars`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * Returns char profile
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnCharProfile(requestParameters: ReturnCharProfileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CharProfileSDKModel>;
    public returnCharProfile(requestParameters: ReturnCharProfileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CharProfileSDKModel>>;
    public returnCharProfile(requestParameters: ReturnCharProfileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CharProfileSDKModel>>;
    public returnCharProfile(requestParameters: ReturnCharProfileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling returnCharProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["JWTAuth"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CharProfileSDKModel>(`${this.configuration.basePath}/api/char/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * Returns list of User\&#39;s Chars
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnUserCharList(requestParameters: ReturnUserCharListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CharSearchResponseSDKModel>;
    public returnUserCharList(requestParameters: ReturnUserCharListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CharSearchResponseSDKModel>>;
    public returnUserCharList(requestParameters: ReturnUserCharListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CharSearchResponseSDKModel>>;
    public returnUserCharList(requestParameters: ReturnUserCharListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling returnUserCharList.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["JWTAuth"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CharSearchResponseSDKModel>(`${this.configuration.basePath}/api/user/${encodeURIComponent(String(id))}/chars`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Not Ready
     * Returns list of Chars (CharProfile) &amp; Parameters for Search &amp; table
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchChars(requestParameters: SearchCharsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CharSearchResponseSDKModel>;
    public searchChars(requestParameters: SearchCharsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CharSearchResponseSDKModel>>;
    public searchChars(requestParameters: SearchCharsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CharSearchResponseSDKModel>>;
    public searchChars(requestParameters: SearchCharsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const name = requestParameters.name;
        const code = requestParameters.code;
        const surname = requestParameters.surname;
        const game = requestParameters.game;
        const server = requestParameters.server;
        const fraction = requestParameters.fraction;
        const charRace = requestParameters.charRace;
        const charClass = requestParameters.charClass;
        const charGender = requestParameters.charGender;
        const levelMin = requestParameters.levelMin;
        const levelMax = requestParameters.levelMax;
        const gearScoreMin = requestParameters.gearScoreMin;
        const gearScoreMax = requestParameters.gearScoreMax;
        const charRole = requestParameters.charRole;
        const user = requestParameters.user;
        const isVerified = requestParameters.isVerified;
        const page = requestParameters.page;
        const perPage = requestParameters.perPage;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'code');
        }
        if (surname !== undefined && surname !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>surname, 'surname');
        }
        if (game !== undefined && game !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>game, 'game');
        }
        if (server !== undefined && server !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>server, 'server');
        }
        if (fraction !== undefined && fraction !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fraction, 'fraction');
        }
        if (charRace !== undefined && charRace !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>charRace, 'char_race');
        }
        if (charClass !== undefined && charClass !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>charClass, 'char_class');
        }
        if (charGender !== undefined && charGender !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>charGender, 'char_gender');
        }
        if (levelMin !== undefined && levelMin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>levelMin, 'level_min');
        }
        if (levelMax !== undefined && levelMax !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>levelMax, 'level_max');
        }
        if (gearScoreMin !== undefined && gearScoreMin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gearScoreMin, 'gear_score_min');
        }
        if (gearScoreMax !== undefined && gearScoreMax !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gearScoreMax, 'gear_score_max');
        }
        if (charRole !== undefined && charRole !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>charRole, 'char_role');
        }
        if (user !== undefined && user !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>user, 'user');
        }
        if (isVerified !== undefined && isVerified !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isVerified, 'is_verified');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CharSearchResponseSDKModel>(`${this.configuration.basePath}/api/search/chars`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
