export namespace AuthActions {
  export class SendLoginLink {
    static readonly type = '[Auth] Send magic link';
    constructor(readonly email: string) {}
  }

  export class LoginWithPassword {
    static readonly type = '[Auth] Login with password';
    constructor(
      readonly email: string,
      readonly password: string,
    ) {}
  }
  export class LoginWithLink {
    static readonly type = '[Auth] Login with link';
    constructor(
      readonly userId: number,
      readonly token: string,
    ) {}
  }

  export class Regisrtation {
    static readonly type = '[Auth] Registration';
    constructor(public email: string) {}
  }

  export class LoginWithToken {
    static readonly type = '[Auth] Login with token';
    constructor() {}
  }

  export class SetTokenFromStorage {
    static readonly type = '[Auth] Set token from local storage';
    constructor() {}
  }

  export class getMe {
    static readonly type = '[Auth] Get user data';
    constructor() {}
  }

  export class Logout {
    static readonly type = '[Auth] Logout';
    constructor() {}
  }
}
