import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { LeftMenuComponent } from '@components/sidebars/left-sidebar/left-sidebar.component';
import { RightMenuComponent } from '@components/sidebars/right-sidebar/right-sidebar.component';
import { WelcomeComponent } from '@components/welcome/welcome.component';
import { WidthContainerModule } from '@components/width-container/width-container.module';
import { RouteTransformerDirectiveModule } from '@shared/directives/route-transformer-directive.directive';
import { AppComponent } from './app.component';

export const MAIN_MODULE_COMPONENTS = [
  AppComponent,
  PageHeaderComponent,
  WelcomeComponent,
  LeftMenuComponent,
  RightMenuComponent,
];

const MAT_MODULES = [
  MatListModule,
  MatIconModule,
  MatExpansionModule,
  MatButtonModule,
  MatProgressBarModule,
  MatToolbarModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatCardModule,
];

export const MAIN_MODULE_IMPORTS = [
  ...MAT_MODULES,
  RouteTransformerDirectiveModule,
  HttpClientModule,
  WidthContainerModule,
];
