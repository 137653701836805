import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { Unsubscriber } from '@shared/services/unsubscriber.service';
import { WindowState } from '@store/ux-states/window/window.state';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-width-container',
  templateUrl: './width-container.component.html',
  styleUrls: ['./width-container.component.scss'],
  providers: [Unsubscriber],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidthContainerComponent implements AfterViewInit {
  @Select(WindowState.isMobile)
  isMobile$: Observable<boolean>;

  @Select(WindowState.isMiddle)
  isMiddle$: Observable<boolean>;

  @Select(WindowState.isLarge)
  isLarge$: Observable<boolean>;

  @ViewChild('innerWrapper')
  innerWrapper: ElementRef<HTMLElement>;

  constructor(private unsubscriber: Unsubscriber, private elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.initObservers();
  }

  private initObservers(): void {
    this.initInnerWrapperObservers();
  }

  private initInnerWrapperObservers(): void {
    this.isMiddle$.pipe(takeUntil(this.unsubscriber.destroy$), filter(Boolean)).subscribe(() => {
      this.innerWrapper.nativeElement.style.width = `${WindowState.defaults.mobileWidth}px`;
    });

    this.isMobile$.pipe(takeUntil(this.unsubscriber.destroy$), filter(Boolean)).subscribe(() => {
      this.innerWrapper.nativeElement.style.width = '100%';
    });

    this.isMobile$.pipe(takeUntil(this.unsubscriber.destroy$)).subscribe((isMobile) => {
      const { classList } = this.elementRef.nativeElement;
      if (isMobile) {
        classList.add('mobile');
      } else if (classList.contains('mobile')) {
        classList.remove('mobile');
      }
    });

    this.isLarge$.pipe(takeUntil(this.unsubscriber.destroy$), filter(Boolean)).subscribe(() => {
      this.innerWrapper.nativeElement.style.width = `${WindowState.defaults.mobileWidth}px`;
    });
  }
}
