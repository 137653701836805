/**
 * ClanHall API
 * ClanHall API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: admin@clanhall.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import {
  LoginResponse200SDKModel,
  LogoutResponse200SDKModel,
  MeResponseSDKModel,
  Response200SDKModel,
  Response201SDKModel,
} from '../model/models';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';


export interface ActivateRequestParams {
  userId?: number;
  token?: string;
}

export interface LoginRequestParams {
  email?: string;
  password?: string;
}

export interface LoginByEmailTokenRequestParams {
  userId?: number;
  token?: string;
}

export interface RegisterRequestParams {
  email?: string;
  /** Flag if user accept offer */
  offer?: boolean;
  /** Flag if user accept privacy */
  privacy?: boolean;
  /** UUID if user came from Advgame */
}

export interface SendEmailTokenRequestParams {
  email: string;
}

export interface AttachDiscordResponse {
  attachDiscordRedirectUrl?: string;
}


@Injectable({
  providedIn: 'root',
})
export class AuthSDKService {

  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key,
            (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Ready
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public activate(requestParameters: ActivateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<Response201SDKModel>;
  public activate(requestParameters: ActivateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<Response201SDKModel>>;
  public activate(requestParameters: ActivateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<Response201SDKModel>>;
  public activate(requestParameters: ActivateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {
    const userId = requestParameters.userId;
    const token = requestParameters.token;

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/x-www-form-urlencoded',
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (userId !== undefined) {
      formParams = formParams.append('user_id', <any>userId) as any || formParams;
    }
    if (token !== undefined) {
      formParams = formParams.append('token', <any>token) as any || formParams;
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<Response201SDKModel>(`${this.configuration.basePath}/api/activate`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Ready
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public login(requestParameters: LoginRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<LoginResponse200SDKModel>;
  public login(requestParameters: LoginRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<LoginResponse200SDKModel>>;
  public login(requestParameters: LoginRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<LoginResponse200SDKModel>>;
  public login(requestParameters: LoginRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {
    const email = requestParameters.email;
    const password = requestParameters.password;

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data',
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (email !== undefined) {
      formParams = formParams.append('email', <any>email) as any || formParams;
    }
    if (password !== undefined) {
      formParams = formParams.append('password', <any>password) as any || formParams;
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<LoginResponse200SDKModel>(`${this.configuration.basePath}/api/login`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Ready
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loginByEmailToken(requestParameters: LoginByEmailTokenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<LoginResponse200SDKModel>;
  public loginByEmailToken(requestParameters: LoginByEmailTokenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<LoginResponse200SDKModel>>;
  public loginByEmailToken(requestParameters: LoginByEmailTokenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<LoginResponse200SDKModel>>;
  public loginByEmailToken(requestParameters: LoginByEmailTokenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {
    const userId = requestParameters.userId;
    const token = requestParameters.token;

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data',
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (userId !== undefined) {
      formParams = formParams.append('user_id', <any>userId) as any || formParams;
    }
    if (token !== undefined) {
      formParams = formParams.append('token', <any>token) as any || formParams;
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<LoginResponse200SDKModel>(`${this.configuration.basePath}/api/auth/email_token`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Ready
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public logout(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<LogoutResponse200SDKModel>;
  public logout(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<LogoutResponse200SDKModel>>;
  public logout(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<LogoutResponse200SDKModel>>;
  public logout(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (JWTAuth) required
    if (this.configuration.apiKeys) {
      const key: string | undefined = this.configuration.apiKeys['JWTAuth'] || this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<LogoutResponse200SDKModel>(`${this.configuration.basePath}/api/logout`,
      null,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Ready
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public me(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<MeResponseSDKModel>;
  public me(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<MeResponseSDKModel>>;
  public me(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<MeResponseSDKModel>>;
  public me(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (JWTAuth) required
    if (this.configuration.apiKeys) {
      const key: string | undefined = this.configuration.apiKeys['JWTAuth'] || this.configuration.apiKeys['Authorization'];
      if (key) {
        headers = headers.set('Authorization', key);
      }
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<MeResponseSDKModel>(`${this.configuration.basePath}/api/me`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Ready
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public register(requestParameters: RegisterRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<Response201SDKModel>;
  public register(requestParameters: RegisterRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<Response201SDKModel>>;
  public register(requestParameters: RegisterRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<Response201SDKModel>>;
  public register(requestParameters: RegisterRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {
    const email = requestParameters.email;
    const offer = requestParameters.offer;
    const privacy = requestParameters.privacy;

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/x-www-form-urlencoded',
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any; };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (email !== undefined) {
      formParams = formParams.append('email', <any>email) as any || formParams;
    }
    if (offer !== undefined) {
      formParams = formParams.append('offer', <any>offer) as any || formParams;
    }
    if (privacy !== undefined) {
      formParams = formParams.append('privacy', <any>privacy) as any || formParams;
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<Response201SDKModel>(`${this.configuration.basePath}/api/register`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Ready
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public registerByDiscordRedirect(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<Response200SDKModel>;
  public registerByDiscordRedirect(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<Response200SDKModel>>;
  public registerByDiscordRedirect(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<Response200SDKModel>>;
  public registerByDiscordRedirect(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Response200SDKModel>(`${this.configuration.basePath}/api/auth/discord/redirect`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  public attachDiscordRedirect(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<AttachDiscordResponse>;
  public attachDiscordRedirect(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<AttachDiscordResponse>>;
  public attachDiscordRedirect(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<AttachDiscordResponse>>;
  public attachDiscordRedirect(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Response200SDKModel>(`${this.configuration.basePath}/api/attach_discord/redirect`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Not Ready
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendEmailToken(requestParameters: SendEmailTokenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<Response200SDKModel>;
  public sendEmailToken(requestParameters: SendEmailTokenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpResponse<Response200SDKModel>>;
  public sendEmailToken(requestParameters: SendEmailTokenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<HttpEvent<Response200SDKModel>>;
  public sendEmailToken(requestParameters: SendEmailTokenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json'
  }): Observable<any> {
    const email = requestParameters.email;
    if (email === null || email === undefined) {
      throw new Error('Required parameter email was null or undefined when calling sendEmailToken.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (email !== undefined && email !== null) {
      queryParameters = this.addToHttpParams(queryParameters,
        <any>email, 'email');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Response200SDKModel>(`${this.configuration.basePath}/api/auth/send_email_token`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

}
