<ng-container *transloco="let t">
  <mat-toolbar class="header">
    <div
      *ngIf="(isMobile$ | async) === false"
      [innerHTML]="(leftText$ | async) || defaultLeftText"
      class="logo"
      routeTransformer
    ></div>
    <button
      (click)="toggleLeftMobileSidebar()"
      *ngIf="isMobile$ | async"
      mat-icon-button
      type="button"
    >
      <mat-icon aria-label="Открыть меню">menu</mat-icon>
    </button>
    <button
      (click)="toggleLeftMiddleSidebar()"
      *ngIf="isMiddle$ | async"
      mat-icon-button
      type="button"
    >
      <mat-icon aria-label="Открыть меню">menu</mat-icon>
    </button>
    <h1 class="title">
      <span [@title]="title$ | async" [innerText]="t(title$ | async)"></span>
    </h1>
    <button
      (click)="toggleRightMobileSidebar()"
      *ngIf="isMobile$ | async"
      mat-icon-button
      type="button"
    >
      <mat-icon aria-label="Открыть меню">menu</mat-icon>
    </button>
    <button
      (click)="toggleRightMiddleSidebar()"
      *ngIf="isMiddle$ | async"
      class="toggle-button"
      mat-icon-button
      type="button"
    >
      <mat-icon aria-label="Открыть меню">menu</mat-icon>
    </button>
    <div
      *ngIf="(isMobile$ | async) === false"
      [innerHTML]="rightText$ | async"
      class="nickname"
      routeTransformer
    ></div>
    <mat-progress-bar *ngIf="loading$ | async" [@loading] mode="indeterminate"></mat-progress-bar>
  </mat-toolbar>
  <mat-toolbar
    color="warn"
    style="display: flex; height: 32px; cursor: pointer"
    [routerLink]="myProfileRoutesService.myProfileLink$ | async"
    *ngIf="showEmptyUserDataAnnouncement$ | async"
  >
    <div style="margin: auto">Заполните свой профиль, чтобы похожие игроки могли вас найти</div>
  </mat-toolbar>
</ng-container>
