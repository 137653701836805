import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnitChildParamsDirectApiService } from '@modules/units/shared/services/unit-child-params-direct-api.service';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NgxsModule } from '@ngxs/store';
import { OPTIONS_CONFIG, STATES_MODULES } from './store.config';

@NgModule({
  imports: [CommonModule, NgxsModule.forRoot(STATES_MODULES, OPTIONS_CONFIG)],
  providers: [UnitChildParamsDirectApiService],
  exports: [NgxsModule],
})
export class NgxsStoreModule {}
