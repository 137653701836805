import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthSDKService } from '@clanhall-sdk/api/auth.sdk.service';
import { Store } from '@ngxs/store';
import { DOC_KEY } from '@shared/constants/doc-keys-query-params.constants';
import { AnalyticsService, GoalNameEnum } from '@shared/services/analytics.service';
import { AuthActions } from '@store/system-states/auth/auth.actions';
import { WINDOW } from 'features/common';

@Component({
  selector: 'app-add-discord-modal',
  templateUrl: './add-discord-modal.component.html',
  styleUrls: ['./add-discord-modal.component.scss'],
})
export class AddDiscordModalComponent {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<AddDiscordModalComponent>,
    private store: Store,
    @Inject(WINDOW) private window: Window,
    private authSDKService: AuthSDKService,
    private analyticsService: AnalyticsService,
  ) {}

  openDoc(docKey: 'offer' | 'privacy' | string): void {
    this.router.navigate(['docs'], { queryParams: { [DOC_KEY]: docKey } });
  }

  goToDiscord(): void {
    this.analyticsService.trackEventByGoalName(GoalNameEnum.mainLinkDiscrodNow);
    this.analyticsService.trackEventByGoalNameForYandex(GoalNameEnum.mainLinkDiscrodNowYm);

    this.authSDKService.attachDiscordRedirect().subscribe((url) => {
      this.window.location = url.attachDiscordRedirectUrl;
    });
  }

  close(): void {
    this.analyticsService.trackEventByGoalName(GoalNameEnum.mainLinkDiscrodLater);
    this.analyticsService.trackEventByGoalNameForYandex(GoalNameEnum.mainLinkDiscrodLaterYm);

    this.store.dispatch(new AuthActions.SetDiscordAttachState(true));
    this.dialogRef.close();
  }
}
