<ng-container *transloco="let t">
  @if (isMobile$ | async) {
    <a class="left-menu-logo-container" routerLink="/" (click)="closeSidebar()">
      <img class="left-menu-logo-container__logo" alt="ClanHall" src="/assets/logo/logo@192.png" />
    </a>
  }
  <mat-divider></mat-divider>
  @if ((leftSidebar$ | async).isFull) {
    <h2 class="list-header">
      <mat-icon>search</mat-icon>{{ t('NO_MODULE_COMPONENTS.SIDEBARS.LEFT.FIND') }}
    </h2>
    <mat-nav-list>
      <div mat-subheader></div>
      @for (menuItem of searchMenu; track menuItem.text) {
        @if (menuItem.ngIfAsync | async) {
          <mat-list-item [routerLink]="menuItem.src" (click)="closeSidebar()">
            <mat-icon matListItemIcon>{{ menuItem.icon }}</mat-icon>
            <a matLine [innerHTML]="t(menuItem.text)"></a>
          </mat-list-item>
        }
      }
    </mat-nav-list>
  }
  <mat-divider></mat-divider>
  <mat-nav-list>
    @for (menuItem of accountMenu; track menuItem.text) {
      @if (menuItem.ngIfAsync | async) {
        <mat-list-item
          [routerLink]="menuItem.src || (menuItem.srcAsync | async)"
          (click)="closeSidebar()"
        >
          <mat-icon aria-hidden="true" matListItemIcon>{{ menuItem.icon }}</mat-icon>
          @if ((leftSidebar$ | async).isFull) {
            <a matLine [innerHTML]="t(menuItem.text)"></a>
          }
        </mat-list-item>
      }
    }
  </mat-nav-list>
</ng-container>
