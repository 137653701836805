import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';

import { AuthSDKService, LoginResponse200SDKModel } from 'data-access/clanhall-sdk';

import { AuthActions } from './auth.actions';
import { AuthStateModel } from './auth.model';
import { AUTH_STATE_TOKEN } from './auth.toke';

function getDefaults(): AuthStateModel {
  return {
    userID: undefined,
    userName: undefined,
    accessToken: undefined,
    tokenExpireDate: undefined,
    loggedIn: false,
  };
}

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: getDefaults(),
})
@Injectable()
export class AuthState {
  authSDKService = inject(AuthSDKService);

  constructor() {}

  @Action(AuthActions.SetTokenFromStorage)
  setTokenFromStorage(ctx: StateContext<AuthStateModel>, {}: AuthActions.SetTokenFromStorage) {
    ctx.setState({
      userID: Number(localStorage.getItem('userId')),
      accessToken: localStorage.getItem('token') || '',
      tokenExpireDate: 0,
      userName: '',
      loggedIn: false,
    });
  }

  @Action(AuthActions.SendLoginLink)
  sendAuthLink(ctx: StateContext<AuthStateModel>, { email }: AuthActions.SendLoginLink) {
    return this.authSDKService.sendEmailToken({ email: email });
  }

  @Action(AuthActions.LoginWithLink)
  loginWithLink(ctx: StateContext<AuthStateModel>, { userId, token }: AuthActions.LoginWithLink) {
    return this.authSDKService.loginByEmailToken({ userId: userId, token: token }).pipe(
      tap((user) => {
        this.getDataLogin(user, ctx);
        this.saveToken(user);
      }),
    );
  }

  @Action(AuthActions.LoginWithPassword)
  loginWithPassword(
    ctx: StateContext<AuthStateModel>,
    { email, password }: AuthActions.LoginWithPassword,
  ) {
    return this.authSDKService.login({ email: email, password: password }).pipe(
      tap((user) => {
        this.getDataLogin(user, ctx);
        this.saveToken(user);
      }),
    );
  }

  @Action(AuthActions.Regisrtation)
  registration(ctx: StateContext<AuthStateModel>, { email }: AuthActions.Regisrtation) {
    return this.authSDKService.register({ email: email, offer: true, privacy: true });
  }

  @Action(AuthActions.getMe)
  getMe(ctx: StateContext<AuthStateModel>, {}: AuthActions.getMe) {
    return this.authSDKService.me().pipe(tap((user) => this.getDataMe(user, ctx)));
  }

  @Action(AuthActions.Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    localStorage.removeItem('token');
    ctx.setState(getDefaults());
  }

  private getDataLogin(response: LoginResponse200SDKModel, context: StateContext<AuthStateModel>) {
    context.patchState({
      userID: response.userId,
      userName: response.userName,
      accessToken: response.accessToken,
      tokenExpireDate: moment().add(response.expiresIn, 'seconds').unix(),
      loggedIn: true,
    });
  }
  private getDataMe(response: LoginResponse200SDKModel, context: StateContext<AuthStateModel>) {
    context.patchState({
      userID: response.userId,
      userName: response.userName,
      loggedIn: true,
    });
  }

  private saveToken(response: LoginResponse200SDKModel) {
    localStorage.setItem('token', response.accessToken ? response.accessToken : '');
  }
}
