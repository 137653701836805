import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AbstractObserverService } from '@shared/services/observer/abstract-observer.service';
import { WindowActions } from '@store/ux-states/window/window.action';
import { WindowState } from '@store/ux-states/window/window.state';
import { WINDOW } from 'features/common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowStateObserverService implements AbstractObserverService {
  constructor(
    private store: Store,
    @Inject(WINDOW) private window: Window,
  ) {}

  initObserver(): Observable<void> {
    this.setWindowSize();

    this.window.addEventListener('resize', () => {
      this.setWindowSize();
    });

    return of<void>();
  }

  private setWindowSize(): void {
    const oldInnerWidth = this.store.selectSnapshot(WindowState.innerWidth);
    const newInnerWidth = this.window.innerWidth;

    if (
      (!oldInnerWidth && this.window.innerWidth) ||
      Math.abs(oldInnerWidth - newInnerWidth) >= 10
    ) {
      this.store.dispatch(new WindowActions.SetScreenWidth(this.window.innerWidth));
    }
  }
}
