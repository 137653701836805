import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ClanhallSDKApiModule } from '@clanhall-sdk/api.module';
import { AuthSDKService } from '@clanhall-sdk/api/auth.sdk.service';
import { Configuration } from '@clanhall-sdk/configuration';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { CustomReuseStrategy } from '@shared/classes/reuse-strategy';
import { getRuPaginatorIntl } from '@shared/functions/ru-paginator';
import { AuthErrorInterceptor } from '@shared/interceptors/auth-error.interceptor';
import { JwtInterceptor } from '@shared/interceptors/jwt.interceptor';
import { KeyToCamelCaseInterceptor } from '@shared/interceptors/key-to-camel-case.interceptor';
import { NgxsStoreModule } from '@store/store.module';
import { AppConfigActions } from '@store/system-states/app-config/app-config.actions';
import { AuthActions } from '@store/system-states/auth/auth.actions';
import { WINDOW_PROVIDER } from 'features/common';
import { forkJoin, Observable } from 'rxjs';

const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: KeyToCamelCaseInterceptor, multi: true },
];

const PROVIDE_OPT = [
  { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
  { provide: MAT_TABS_CONFIG, useValue: { animationDuration: 0 } },
  { provide: MatPaginatorIntl, useValue: getRuPaginatorIntl() },
  { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
];

function appInit(
  authSDKService: AuthSDKService,
  store: Store,
): () => Observable<unknown> | Promise<unknown> {
  return () =>
    forkJoin({
      config: store.dispatch(new AppConfigActions.GetAppConfig()),
      me: store.dispatch(new AuthActions.InitUser()),
    });
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClanhallSDKApiModule.forRoot(() => new Configuration({ basePath: environment.sdkBaseUrl })),
    NgxsStoreModule,
  ],
  providers: [...INTERCEPTORS, ...PROVIDE_OPT, provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded.');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        WINDOW_PROVIDER,
        {
          provide: APP_INITIALIZER,
          useFactory: appInit,
          deps: [AuthSDKService, Store],
          multi: true,
        },
      ],
    };
  }
}
