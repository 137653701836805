import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthSDKService } from '@clanhall-sdk/api/auth.sdk.service';
import { LoginResponse200SDKModel } from '@clanhall-sdk/model/loginResponse200.sdk.model';
import { MeResponseSDKModel } from '@clanhall-sdk/model/meResponse.sdk.model';
import { Response200SDKModel } from '@clanhall-sdk/model/response200.sdk.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import { SnackbarService } from '@shared/services/snackbar.service';
import { UserUnitActions } from '@store/common-states/units/user/user-unit.actions';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { AuthActions } from './auth.actions';

export interface AuthStateModel {
  userName: string;
  accessToken: string;
  accessTokenExpiresInDate: string;
  discordIsAttached: boolean;
}

const defaults: AuthStateModel = {
  userName: undefined,
  accessToken: undefined,
  accessTokenExpiresInDate: undefined,
  discordIsAttached: undefined,
};

@State<AuthStateModel>({
  name: 'authStateModule',
  defaults,
})
@Injectable()
export class AuthState {
  constructor(
    private authSDKService: AuthSDKService,
    private snackbarService: SnackbarService,
  ) {}

  @Selector()
  static userName(state: AuthStateModel): AuthStateModel['userName'] {
    return state.userName;
  }

  @Selector()
  static isLoggedIn(state: AuthStateModel): boolean {
    return !!state.accessToken;
  }

  @Selector()
  static accessToken(state: AuthStateModel): AuthStateModel['accessToken'] {
    return state.accessToken;
  }

  @Selector()
  static discordIsAttached(state: AuthStateModel): AuthStateModel['discordIsAttached'] {
    return state.discordIsAttached;
  }

  @Action(AuthActions.SetDiscordAttachState)
  changeDiscordAttachState(
    context: StateContext<AuthStateModel>,
    { payload }: AuthActions.SetDiscordAttachState,
  ): Observable<void> {
    if (!context.getState().discordIsAttached) {
      context.patchState({ discordIsAttached: payload });
    }
    return undefined$();
  }

  @Action(AuthActions.LoginWithEmail)
  loginWithEmail(
    context: StateContext<AuthStateModel>,
    { payload }: AuthActions.LoginWithEmail,
  ): Observable<void> {
    return this.authSDKService
      .login(payload)
      .pipe(switchMap((response: LoginResponse200SDKModel) => this.login(response, context)));
  }

  @Action(AuthActions.Logout)
  logout(context: StateContext<AuthStateModel>): Observable<void> {
    return this.authSDKService.logout().pipe(
      tap(() => localStorage.removeItem('token')),
      switchMapTo(context.dispatch(new AuthActions.ClearAuthState())),
      switchMapTo(undefined$()),
      catchError(() => undefined$()),
    );
  }

  @Action(AuthActions.RegisterWithEmail)
  registation(context: StateContext<AuthStateModel>, { payload }: AuthActions.RegisterWithEmail) {
    return this.authSDKService.register(payload);
  }

  /**
   * Method used at app init.
   *
   * Note! "/me" is an exeption of AuthErrorInterceptor.
   *
   * @param context StateContext<AuthStateModel>
   */
  @Action(AuthActions.InitUser)
  initUser(context: StateContext<AuthStateModel>): Observable<void> {
    if (localStorage.getItem('token')) {
      context.patchState({ accessToken: localStorage.getItem('token') });
    }
    const currentState: AuthStateModel = context.getState();
    return currentState.accessToken
      ? this.authSDKService.me().pipe(
          tap((response: MeResponseSDKModel) => {
            context.patchState({
              userName: response.userName,
              discordIsAttached: response.discordIsAttached,
            });
            context.dispatch(new UserUnitActions.AddMyUserId(response.userId));
          }),
          catchError(() => context.dispatch(new AuthActions.ClearAuthState())),
          switchMapTo(undefined$()),
        )
      : context.dispatch(new AuthActions.ClearAuthState());
  }

  @Action(AuthActions.LoginWithToken)
  loginWithToken(
    context: StateContext<AuthStateModel>,
    { userId, token }: AuthActions.LoginWithToken,
  ): Observable<void> {
    return this.authSDKService
      .loginByEmailToken({
        userId,
        token,
      })
      .pipe(switchMap((response: LoginResponse200SDKModel) => this.login(response, context)));
  }

  @Action(AuthActions.ClearAuthState)
  clearAuthState(context: StateContext<AuthStateModel>): Observable<void> {
    context.setState(defaults);
    return undefined$();
  }

  @Action(AuthActions.SendAuthLinkToEmail)
  sendAuthLinkToEmail(
    context: StateContext<AuthStateModel>,
    { email }: AuthActions.SendAuthLinkToEmail,
  ): Observable<any> {
    return this.authSDKService.sendEmailToken({ email }).pipe(
      tap((response: Response200SDKModel) => {
        this.snackbarService.showOk(response.message, 60000);
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.snackbarService.showError(errorResponse);

        return throwError(errorResponse);
      }),
    );
  }

  private login(
    response: LoginResponse200SDKModel,
    context: StateContext<AuthStateModel>,
  ): Observable<void> {
    const accessTokenExpiresInDate: string = moment().add(response.expiresIn, 'seconds').format();
    context.patchState({
      userName: response.userName,
      accessToken: response.accessToken,
      discordIsAttached: response.discordIsAttached,
      accessTokenExpiresInDate,
    });
    localStorage.setItem('token', response.accessToken ? response.accessToken : '');
    return context.dispatch(new UserUnitActions.AddMyUserId(response.userId));
  }
}
