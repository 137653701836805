<mat-card>
  <mat-card-content>
    <div class="add-discord-container">
      <div class="add-discord-title">Привяжите учетную запись Discord</div>
      <img class="add-discord-img" [src]="'assets/auth-methods/discord-no-text.svg'" />
      <div class="add-discord-text">
        Это нужно для того, чтобы вы получали социальные уведомления, такие как:
        <ul>
          <li>кто-то добавил вас в друзья</li>
          <li>кто-то пригласил вас в гильдию</li>
          <li>и т.п.</li>
        </ul>
      </div>
      <div class="add-discord-actions">
        <button (click)="goToDiscord()" color="accent" mat-flat-button>Привязать сейчас</button>

        <button (click)="close()" color="primary" mat-button>Привязать позже</button>
      </div>
      <div class="divider"></div>
      <div class="docs-links">
        <span>
          При входе в систему вы принимаете
          <a (click)="openDoc('offer')">пользовательское соглашение</a> и
          <a (click)="openDoc('privacy')">политику конфиденциальности</a>.
        </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
