/**
 * ClanHall API
 * ClanHall API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: admin@clanhall.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { GuildCharListResponseSDKModel } from '../model/models';
import { GuildListResponseSDKModel } from '../model/models';
import { GuildProfileSDKModel } from '../model/models';
import { GuildSearchResponseSDKModel } from '../model/models';
import { GuildUserListResponseSDKModel } from '../model/models';
import { Response404SDKModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ReturnGuildCharsRequestParams {
    id: number;
}

export interface ReturnGuildListRequestParams {
    page?: number;
    perPage?: number;
}

export interface ReturnGuildProfileRequestParams {
    id: number;
}

export interface ReturnGuildUsersRequestParams {
    id: number;
}

export interface SearchGuildsRequestParams {
    name?: string;
    code?: number;
    game?: number;
    server?: number;
    fraction?: number;
    guildWay?: number;
    leader?: number;
    owner?: number;
    founder?: number;
    comm?: string;
    amountMin?: number;
    amountMax?: number;
    /** Can be 0-1439 */
    primeTimeBegin?: number;
    /** Can be 0-2879 */
    primeTimeEnd?: number;
    siege?: boolean;
    boss?: boolean;
    raid?: boolean;
    pvp?: boolean;
    rp?: boolean;
    communication?: boolean;
    sea?: boolean;
    dungeon?: boolean;
    pack?: boolean;
    isVerified?: boolean;
    page?: number;
    perPage?: number;
}


@Injectable({
  providedIn: 'root'
})
export class GuildSDKService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Ready
     * Returns Guild Chars
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnGuildChars(requestParameters: ReturnGuildCharsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GuildCharListResponseSDKModel>;
    public returnGuildChars(requestParameters: ReturnGuildCharsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GuildCharListResponseSDKModel>>;
    public returnGuildChars(requestParameters: ReturnGuildCharsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GuildCharListResponseSDKModel>>;
    public returnGuildChars(requestParameters: ReturnGuildCharsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling returnGuildChars.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GuildCharListResponseSDKModel>(`${this.configuration.basePath}/api/guild/${encodeURIComponent(String(id))}/chars`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * Returns list of Guilds &amp; Parameters for Search &amp; table
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnGuildList(requestParameters: ReturnGuildListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GuildListResponseSDKModel>;
    public returnGuildList(requestParameters: ReturnGuildListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GuildListResponseSDKModel>>;
    public returnGuildList(requestParameters: ReturnGuildListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GuildListResponseSDKModel>>;
    public returnGuildList(requestParameters: ReturnGuildListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        const perPage = requestParameters.perPage;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GuildListResponseSDKModel>(`${this.configuration.basePath}/api/guilds`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * Returns guild profile
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnGuildProfile(requestParameters: ReturnGuildProfileRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GuildProfileSDKModel>;
    public returnGuildProfile(requestParameters: ReturnGuildProfileRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GuildProfileSDKModel>>;
    public returnGuildProfile(requestParameters: ReturnGuildProfileRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GuildProfileSDKModel>>;
    public returnGuildProfile(requestParameters: ReturnGuildProfileRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling returnGuildProfile.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GuildProfileSDKModel>(`${this.configuration.basePath}/api/guild/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * Returns Guild Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnGuildUsers(requestParameters: ReturnGuildUsersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GuildUserListResponseSDKModel>;
    public returnGuildUsers(requestParameters: ReturnGuildUsersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GuildUserListResponseSDKModel>>;
    public returnGuildUsers(requestParameters: ReturnGuildUsersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GuildUserListResponseSDKModel>>;
    public returnGuildUsers(requestParameters: ReturnGuildUsersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling returnGuildUsers.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GuildUserListResponseSDKModel>(`${this.configuration.basePath}/api/guild/${encodeURIComponent(String(id))}/users`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Not Ready
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchGuilds(requestParameters: SearchGuildsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GuildSearchResponseSDKModel>;
    public searchGuilds(requestParameters: SearchGuildsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GuildSearchResponseSDKModel>>;
    public searchGuilds(requestParameters: SearchGuildsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GuildSearchResponseSDKModel>>;
    public searchGuilds(requestParameters: SearchGuildsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const name = requestParameters.name;
        const code = requestParameters.code;
        const game = requestParameters.game;
        const server = requestParameters.server;
        const fraction = requestParameters.fraction;
        const guildWay = requestParameters.guildWay;
        const leader = requestParameters.leader;
        const owner = requestParameters.owner;
        const founder = requestParameters.founder;
        const comm = requestParameters.comm;
        const amountMin = requestParameters.amountMin;
        const amountMax = requestParameters.amountMax;
        const primeTimeBegin = requestParameters.primeTimeBegin;
        const primeTimeEnd = requestParameters.primeTimeEnd;
        const siege = requestParameters.siege;
        const boss = requestParameters.boss;
        const raid = requestParameters.raid;
        const pvp = requestParameters.pvp;
        const rp = requestParameters.rp;
        const communication = requestParameters.communication;
        const sea = requestParameters.sea;
        const dungeon = requestParameters.dungeon;
        const pack = requestParameters.pack;
        const isVerified = requestParameters.isVerified;
        const page = requestParameters.page;
        const perPage = requestParameters.perPage;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (code !== undefined && code !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>code, 'code');
        }
        if (game !== undefined && game !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>game, 'game');
        }
        if (server !== undefined && server !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>server, 'server');
        }
        if (fraction !== undefined && fraction !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fraction, 'fraction');
        }
        if (guildWay !== undefined && guildWay !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>guildWay, 'guild_way');
        }
        if (leader !== undefined && leader !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leader, 'leader');
        }
        if (owner !== undefined && owner !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>owner, 'owner');
        }
        if (founder !== undefined && founder !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>founder, 'founder');
        }
        if (comm !== undefined && comm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>comm, 'comm');
        }
        if (amountMin !== undefined && amountMin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>amountMin, 'amount_min');
        }
        if (amountMax !== undefined && amountMax !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>amountMax, 'amount_max');
        }
        if (primeTimeBegin !== undefined && primeTimeBegin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>primeTimeBegin, 'prime_time_begin');
        }
        if (primeTimeEnd !== undefined && primeTimeEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>primeTimeEnd, 'prime_time_end');
        }
        if (siege !== undefined && siege !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>siege, 'siege');
        }
        if (boss !== undefined && boss !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>boss, 'boss');
        }
        if (raid !== undefined && raid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>raid, 'raid');
        }
        if (pvp !== undefined && pvp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pvp, 'pvp');
        }
        if (rp !== undefined && rp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rp, 'rp');
        }
        if (communication !== undefined && communication !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>communication, 'communication');
        }
        if (sea !== undefined && sea !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sea, 'sea');
        }
        if (dungeon !== undefined && dungeon !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dungeon, 'dungeon');
        }
        if (pack !== undefined && pack !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pack, 'pack');
        }
        if (isVerified !== undefined && isVerified !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isVerified, 'is_verified');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GuildSearchResponseSDKModel>(`${this.configuration.basePath}/api/search/guilds`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
